import React, { useContext } from "react";
import ContactFormPipe from "../../components/ContactFormPipe/ContactFormPipe";
import Intro from "../../components/Intro_v2/Intro_v2";
import Meta from "../../components/Meta/Meta";
import Spacer from "../../components/Spacer/Spacer";
import SectionTwoCol from "../../components/SectionTwoCol/SectionTwoCol";
import { DataContext } from "../../contexts/DataContext";
import { HeroLegacy as Hero, Button } from "@3beehivetech/components";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";
import BusinessSlider from "../../components/BusinessSlider/BusinessSlider";
import useScrollController from "../../hooks/useScrollController";
import BusinessIconText from "../../components/BusinessIconText/BusinessIconText";
import BusinessClients from "../../components/BusinessClients/BusinessClients";
import SingleVimeoPlayer from "../../components/SingleVimeoPlayer/SingleVimeoPlayer";
import { StyledGridRow } from "../../commons/Grid";

const Academy = () => {
  const { scrollToCssSelector } = useScrollController();
  const { 
    pageLabels, 
    pageImages,
  } = useContext(DataContext);
  const imageData = pageImages.find(e => e.path === "/2020/images/business/academy/hero_background.jpg");
  toast.configure();

  return (
    <>
      <Meta />

      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify([
            {
              "@context":"https://schema.org",
              "@type": "Service",
              "name": pageLabels.hero.title.replace(/<[^>]*>?/gm, ""),
              "brand": {
                "@type": "Brand",
                "name": "3Bee",
              },
              "description": pageLabels.hero.paragraph.replace(/<[^>]*>?/gm, ""),
            },
          ])}
        </script>
      </Helmet>

      <Hero
        title={
          <div style={{ marginTop: "20vh", textTransform: "uppercase" }} dangerouslySetInnerHTML={{ __html: pageLabels.hero.title }}/>
        }
        paragraph={
          <div style={{ fontWeight: "normal" }} dangerouslySetInnerHTML={{ __html: pageLabels.hero.paragraph }}/>
        }
        headerButtonComponent={
          <Button onClick={() => scrollToCssSelector("#why", 50)}>{pageLabels.hero.cta}</Button>
        }
        headerVariant="transparent"
        backgroundImageComponent={
          <GatsbyImage loading="eager" layout="fullWidth" objectFit="cover" objectPosition="top" style={{
            height: "100%",
            width: "100%",
          }} image={getImage(imageData)} alt={imageData.alt} />
        }
      />
      
      <SectionTwoCol
        id="why"
        title={pageLabels.why.title}
        subtitle1={pageLabels.why.subtilte}
        cta={pageLabels.commons.askAdvice}
        to="#contacts"
        image="/2020/images/business/academy/beekeeper.jpg"
        topImage
      />
      
      <Spacer size={10}/>

      <Intro 
        bigText={pageLabels.activities.title}
        subtitle={pageLabels.activities.subtitle}
        textAlign="center"
      />
      <BusinessIconText
        items={[{
          image: "/2020/images/business/academy/impollination.svg",
          label: pageLabels.activities.education.label,
          text: pageLabels.activities.education.text,
        },{
          image: "/2020/images/business/academy/hive.svg",
          label: pageLabels.activities.beekeeper.label,
          text: pageLabels.activities.beekeeper.text,
        },{
          image: "/2020/images/business/academy/honey.svg",
          label: pageLabels.activities.tasting.label,
          text: pageLabels.activities.tasting.text,
        }]}
      />
      
      <Spacer size={10}/>
      
      <StyledGridRow noMargin>
        <SingleVimeoPlayer
          videoID="746464103"
          thumb="/2020/images/business/academy/academy_thumb.jpg"
        />
      </StyledGridRow>

      <Spacer size={10}/>
      
      <BusinessSlider items={pageLabels.slider.items.map(e => {
        return {
          ...e,
          theme: "white",
          cta: {
            label: pageLabels.commons.askAdvice,
            url: "#contacts",
          },
          ul: e.items,
        };
      })}/>

      <Spacer size={10}/>

      <BusinessClients 
        title={pageLabels.clients.title}
        subtitle={pageLabels.clients.subtitle}
        logos={[
          { image: "/2020/images/business/academy/clients/amazon.png", slug: "amzl-italia-1" },
          { image: "/2020/images/business/academy/clients/calzedonia.png", slug: null },
          { image: "/2020/images/business/academy/clients/ubisoft.png", slug: null },
          { image: "/2020/images/business/academy/clients/davines.png", slug: "loasi-di-davines-1" },
          { image: "/2020/images/business/academy/clients/ferrero.png", slug: "ferrero" },
          { image: "/2020/images/business/academy/clients/nen.png", slug: "nen-1" },
          { image: "/2020/images/business/academy/clients/pernod.png", slug: "pride-beestrees-1" },
          { image: "/2020/images/business/academy/clients/toyota-new.png", slug: "tmhit-1" },
        ]}
      />

      <Spacer size={10}/>

      <ContactFormPipe />
    </>
  );
};

export default Academy;
